
function Home() {
  return (
    <div className="App">
      <h1>Work in progress</h1>
    </div>
  );
}

export default Home;
