import Home from './Home';
import { Routes, Route } from 'react-router-dom';
import Christmas from './Christmas';

const App =  () => {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/roxii' exact element={<Christmas/>} />
      </Routes>
    </div>
  );
}

export default App;
