import './Christmas.scss';
import React, { useEffect } from 'react';
import Snowfall from 'react-snowfall';

const Christmas = () => {

    useEffect(() => {
        document.title = 'Craciun fericit, Roxii!';
    }, []);

  return (
      <div>
      <ul class="lightrope">
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        <li></li>
        </ul>
          <Snowfall
            speed={[0.5, 1]}
            wind={[-0.5, 0.5]}
            snowflakeCount={250}
          />
        <div class="christmas-card">
            <input id="open" type="checkbox" />
            <label class="open" for="open"></label>
          <div class="card-front">
            <div class="christmas-tree"></div>
            <div class="ribbon"></div>
            <div class="text">Cele mai frumoase clipe si momente sa te insoteasca zilnic</div>
            <div class="star"></div>
            <div class="balls"></div>
            <div class="balls2"></div>
            <div class="balls3"></div>
            <div class="balls4"></div>
            <div class="balls5"></div>
          </div>
            
          <div class="card-inside">
              <div class="title">Craciun fericiiit,<br/>ROXII!!!</div>
            <div class="wishes">
              Un Craciun magic si luminos,<br/>
              Visurile sa ti se implineasca,<br/>
              Zambetul, niciodata sa nu-ti piara,<br/>
              Increderea in tine, limita sa nu aiba<br/>
              Sa ai parte de zile minunate,<br/>
              Momente uimitoare, clipe de neuitat<br/>
            <br/>
              Esti o persoana minunata,<br/>
              Ce luminezi tot in jurul tau.<br/>
              Zambetul este de nuitat,<br/>
              Ochii, de privit neincetat,<br/>
              Sufletul, ca soarele arzator,<br/>
              Momentele cu tine, in suflet de agatat<br/>
            <br/>
              Sarbatori minunate iti doresc.<br/>
              Toate dorintele sa ti se implineasca!<br/>
            </div>
            <div class="gift">
              <div class="bow"></div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default Christmas;
